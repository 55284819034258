<template>
  <b-card class="text-center h-100">
    <div class="d-flex justify-content-center mb-3">
      <img :src="logo" alt="Logo" class="img-fluid" style="width: 5rem" />
    </div>
    <hr />
    <div class="row">
      <div class="col-6">
        <h3>Total of Coupons Redeemed</h3>
        <h3 class="mt-3">{{ total }}</h3>
      </div>
      <div class="col-6">
        <h3>Total discount</h3>
        <h3 class="mt-3">{{ discount }}</h3>
      </div>
    </div>
    <b-button class="mt-5" block variant="primary" @click="$emit('seeCupons')"
      >See Patients with coupons</b-button
    >
  </b-card>
</template>

<script>
import { BCard, BButton } from 'bootstrap-vue'
import logo from '@/assets/images/Recurso 11.png'

export default {
  components: {
    BCard,
  },
  props: {
    datarda: {
      type: Object,
      default: () => {},
      required: false,
    },
  },
  watch: {
    datarda() {
      if (!this.datarda) return
      this.total = this.datarda.CAMPAIGN_CUPONS['30'].CUPONS_PAGINATE?.total
      this.discount = this.datarda.CAMPAIGN_CUPONS['30'].TOTAL_DISCOUNT
    },
  },
  data() {
    return {
      total: 0,
      discount: 0,
      logo: logo,
    }
  },
}
</script>

<style scoped lang="scss">
.card {
    --main-color: #000;
    --submain-color: #78858F;
    --bg-color: #fff;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    background: var(--bg-color);
}

.card__img {
    height: 192px;
    width: 100%;
}

.card__img svg {
    height: 100%;
    border-radius: 20px 20px 0 0;
}

.card__avatar {
    position: absolute;
    width: 114px;
    height: 114px;
    background: var(--bg-color);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 15%;
}

.card__avatar svg {
    width: 100px;
    height: 100px;
}

.card__title {
    font-weight: 500;
    font-size: 18px;
    color: var(--main-color);
}

.card__subtitle {
    margin-top: 10px;
    margin-bottom: 3%;
    font-weight: 400;
    font-size: 15px;
    color: var(--submain-color);
}
</style>
