<template>
    <div class="container-fluid h-100 p-0">
      <div class="row h-100 m-0 d-flex justify-content-center align-items-center">
        <div class="col-12 h-100">
          <div class="card w-100 h-100">
            <div class="card-img-top bg-light"></div>
            <div class="card-body text-center">
              <div class="d-inline-block position-relative">
                <b-avatar :src="avatar" alt="" v-if="avatar != null" size="102" />
                <img :src="logo" alt="" v-else class="img-fluid rounded-circle" style="width: 102px; height: 102px;">
              </div>
              <h5 class="card-title mt-3">{{ name }}</h5>
              <p class="card-text text-muted">{{ role }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>

<script>
import {
    BAvatar,
} from "bootstrap-vue";
import axiosU from "@/core/services/api/admin/user";
import logo from "@/assets/images/Recurso 11.png"

export default {
    components: {
        BAvatar,
    },
    props: {
        datarda: {
            type: Object,
            default: () => { },
            required: false
        }
    },
    watch: {
        datarda() {
            if (!this.datarda.user) {
                this.avatar = null
                this.name = 'No user has been selected'
                this.role = 'Please select a user to see his avatar!'
                return
            }
            axiosU.userDetail(this.datarda.user).then(({ registro: { avatar, name, lastname, roles } }) => {
                this.name = name + ' ' + lastname
                this.role = roles.length ? roles[0].name : ''
                this.avatar = avatar
            })
        }
    },
    data() {
        return {
            avatar: null,
            name: 'No user has been selected',
            role: 'Please select a user to see his avatar!',
            logo: logo
        };
    },
};
</script>

<style scoped lang="scss">
.card {
    --main-color: #000;
    --submain-color: #78858F;
    --bg-color: #fff;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    background: var(--bg-color);
}

.card__img {
    height: 192px;
    width: 100%;
}

.card__img svg {
    height: 100%;
    border-radius: 20px 20px 0 0;
}

.card__avatar {
    position: absolute;
    width: 114px;
    height: 114px;
    background: var(--bg-color);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 15%;
}

.card__avatar svg {
    width: 100px;
    height: 100px;
}

.card__title {
    font-weight: 500;
    font-size: 18px;
    color: var(--main-color);
}

.card__subtitle {
    margin-top: 10px;
    margin-bottom: 3%;
    font-weight: 400;
    font-size: 15px;
    color: var(--submain-color);
}
</style>
