<template>
    <div>
        <b-card>
            <b-table stacked :items="items"></b-table>
        </b-card>
    </div>
</template>

<script>
import {
    BContainer,
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
} from "bootstrap-vue";

export default {
    components: {
        BCard,
    },
    props: {
        datarda: {
            type: Object,
            default: () => { },
            required: false
        }
    },
    data() {
        return {
            items: [],
        };
    },
    watch: {
        datarda() {
            if (this.datarda) {
                this.items = []
                this.items.push({
                    total_income: this.datarda.TOTALS.income ?? 0,
                    Total_Expences: this.datarda.TOTALS.expenses ?? 0,
                    Total_Net_Income: this.datarda.TOTALS.net_income ?? 0,
                    Total_Income_Cash: this.datarda.TOTALS.cash ?? 0,
                    Total_Income_CC: this.datarda.TOTALS.cc ?? 0,
                    Patient_Avg_Charge: this.datarda.AVG_TOTAL ?? 0,
                    NC_Avg_Charge: this.datarda.AVG_NC ?? 0,
                    NP_Avg_Charge: this.datarda.AVG_NP ?? 0,
                    NC_and_NP_AVg_Charge: this.datarda.AVG_NC_NP ?? 0,
                    Chargeable_VPD: this.datarda.VPD ?? 0,
                    Less_Chargeable_VPD: this.datarda.NON_PROFIT_VPD ?? 0,
                    Total_VPD: this.datarda.TOTAL_VPD ?? 0,
                })
            }
        }
    },
    methods: {},
};
</script>

<style scoped lang="scss"></style>
