var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"visible":_vm.openModal,"size":"xl","title":_vm.patient},on:{"change":function (val) { return _vm.$emit('update:openModal', val); }}},[_c('b-card-group',{attrs:{"columns":""}},[_c('b-card',{attrs:{"border-variant":"primary","title":"Package"}},[_c('span',[_vm._v(_vm._s(_vm.packageName))])]),_c('b-card',{attrs:{"border-variant":"primary","title":"Diagnostic imagings"}},_vm._l((_vm.visit.diagnostic_imagings),function(ref,key){
var name = ref.name;
var price = ref.price;
return _c('span',{key:key},[_vm._v(" - "+_vm._s(name)+": "+_vm._s(price)),_c('br')])}),0),_c('b-card',{attrs:{"border-variant":"primary","title":"Laboratories"}},_vm._l((_vm.visit.laboratories),function(ref,key){
var name = ref.name;
var price = ref.price;
return _c('span',{key:key},[_vm._v(" - "+_vm._s(name)+": "+_vm._s(price)),_c('br')])}),0),_c('b-card',{attrs:{"border-variant":"primary","title":"Diagnosis"}},_vm._l((_vm.visit.diagnosis),function(ref,key){
var name = ref.name;
return _c('span',{key:key},[_vm._v(" "+_vm._s(name)+" ")])}),0),_c('b-card',{attrs:{"border-variant":"primary","title":"Procedures"}},_vm._l((_vm.visit.procedures),function(ref,key){
var name = ref.name;
var price = ref.price;
return _c('span',{key:key},[_vm._v(" - "+_vm._s(name)+": "+_vm._s(price)),_c('br')])}),0),_c('b-card',{attrs:{"border-variant":"primary","title":"Treatments"}},_vm._l((_vm.visit.treatments),function(ref,key){
var name = ref.name;
var price = ref.price;
return _c('span',{key:key},[_vm._v(" - "+_vm._s(name)+": "+_vm._s(price)),_c('br')])}),0),_c('b-card',{attrs:{"border-variant":"primary","title":"Tests"}},_vm._l((_vm.visit.tests),function(ref,key){
var name = ref.name;
var price = ref.price;
return _c('span',{key:key},[_vm._v(" - "+_vm._s(name)+": "+_vm._s(price)),_c('br')])}),0),_c('b-card',{attrs:{"border-variant":"primary","title":"Review of system"}},_vm._l((_vm.visit.review_of_system),function(ref,key){
var name = ref.name;
return _c('span',{key:key},[_vm._v(" - "+_vm._s(name)),_c('br')])}),0)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }