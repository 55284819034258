<template>
    <b-modal :visible="openModal" @change="(val) => $emit('update:openModal', val)" size="xl" :title="patient">
        <b-card-group columns>
            <b-card border-variant="primary" title="Package">
                <span>{{ packageName }}</span>
            </b-card>
            <b-card border-variant="primary" title="Diagnostic imagings">
                <span v-for="({ name, price }, key) in visit.diagnostic_imagings" :key="key">
                    - {{ name }}: {{ price }}<br />
                </span>
            </b-card>
            <b-card border-variant="primary" title="Laboratories">
                <span v-for="({ name, price }, key) in visit.laboratories" :key="key">
                    - {{ name }}: {{ price }}<br />
                </span>
            </b-card>
            <b-card border-variant="primary" title="Diagnosis">
                <span v-for="({ name }, key) in visit.diagnosis" :key="key">
                    {{ name }}
                </span>
            </b-card>
            <b-card border-variant="primary" title="Procedures">
                <span v-for="({ name, price }, key) in visit.procedures" :key="key">
                    - {{ name }}: {{ price }}<br />
                </span>
            </b-card>
            <b-card border-variant="primary" title="Treatments">
                <span v-for="({ name, price }, key) in visit.treatments" :key="key">
                    - {{ name }}: {{ price }}<br />
                </span>
            </b-card>
            <b-card border-variant="primary" title="Tests">
                <span v-for="({ name, price }, key) in visit.tests" :key="key">
                    - {{ name }}: {{ price }}<br />
                </span>
            </b-card>
            <b-card border-variant="primary" title="Review of system">
                <span v-for="({ name }, key) in visit.review_of_system" :key="key">
                    - {{ name }}<br />
                </span>
            </b-card>
        </b-card-group>
    </b-modal>
</template>

<script>
import {
    BModal,
    BCardGroup,
    BCard
} from "bootstrap-vue";

export default {
    components: {
        BModal,
        BCardGroup,
        BCard
    },
    model: {
        prop: "openModal",
        event: "update:openModal",
    },
    props: {
        visit: {
            type: Object,
            default: () => { },
            required: true
        },
        patient: {
            type: String,
            default: "",
            required: true
        },
        openModal: {
            type: Boolean,
            default: false,
            required: true
        }
    },
    watch: {
        visit() {
            this.packageName = this.visit.package ? `${this.visit.package.name}: ${this.visit.package.price}` : `No package selected!`
        }
    },
    data() {
        return {
            packageName: ''
        };
    },
};
</script>