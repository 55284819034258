<template>
    <div>
      <b-card class="tarjeta mb-4">
        <h1 class="text-center">Admin Data</h1>
        <filterData ref="filtro" @getItems="getItems" @getExcel="getExcel" @dataExcel="dataExcel" :page="page" />
      </b-card>
      <b-row class="match-height">
        <b-col cols="4" class="mb-4">
          <cardUser :datarda="datarda" />
        </b-col>
        <b-col cols="4" class="mb-4">
          <facility :datarda="datarda" />
        </b-col>
        <b-col cols="4" class="mb-4">
          <TotalCupons :datarda="datarda" @seeCupons="$refs.filtro.seeCupons = true" />
        </b-col>
      </b-row>
      <b-row class="match-height">
        <b-col cols="6" class="mb-4">
          <visits :datarda="datarda" />
        </b-col>
        <b-col cols="6" class="mb-4">
          <averages :datarda="datarda" />
        </b-col>
      </b-row>
      <b-row class="match-height">
        <b-col cols="12" class="mb-4">
          <References :datarda="datarda" />
        </b-col>
      </b-row>
      <b-row class="match-height">
        <b-col cols="12">
          <tableData ref="tablarda" :datarda="datarda" :propDataExcel="propDataExcel" @paginated="page = $event" @sendDataTable="dataTable = $event" />
        </b-col>
      </b-row>
      <ToastNotification ref="toast" />
    </div>
  </template>

<script>
import { BCard, BRow, BCol, VBTooltip } from 'bootstrap-vue'
import ToastNotification from '@/components/ToastNotification'
import filterData from './filterData.vue'
import cardUser from './cardUser'
import facility from './facility'
import averages from './averages.vue'
import tableData from './tableData.vue'
import visits from './visits'
import * as XLSX from 'xlsx/xlsx.mjs'
import References from './references.vue'
import TotalCupons from './totalCupons'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    filterData,
    cardUser,
    facility,
    averages,
    tableData,
    visits,
    ToastNotification,
    References,
    TotalCupons,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      datarda: null,
      page: 1,
      dataTable: null,
      propDataExcel: null,
    }
  },
  methods: {
    dataExcel(data) {
      this.propDataExcel = data
    },
    getItems(data) {
      this.datarda = data
    },
    processOfficeVisits(officeVisits) {
      const newOfficeVisits = []
      const specificVisits = []

      officeVisits.forEach(visit => {
        if (Array.isArray(visit[1])) {
          const visitType = visit[0]
          specificVisits.push({ Type_Visit: visitType, Total: visit[1].length })

          visit[1].forEach(detail => {
            const indexVisit = specificVisits.findIndex(
              subDetail => subDetail.Type_Visit === detail.name
            )

            if (indexVisit !== -1) {
              specificVisits[indexVisit].Total++
            } else {
              specificVisits.push({ Type_Visit: detail.name, Total: 1 })
            }
          })
        } else if (typeof visit[1] === 'object') {
          const visitType = visit[0]
          specificVisits.push({
            Type_Visit: visitType,
            Total: Object.entries(visit[1]).length,
          })

          Object.entries(visit[1]).forEach(detail => {
            specificVisits.push({ Type_Visit: detail[0], Total: detail[1] })
          })
        } else if (visit[1]) {
          newOfficeVisits.push({ Type_Visit: visit[0], Total: visit[1] })
        }
      })

      return { newOfficeVisits, specificVisits }
    },
    proccessAverages({ TOTALS, AVG_NC, AVG_NP, AVG_NC_NP, TOTAL_VPD }) {
      const obj = {
        total_income: TOTALS.income ?? 0,
        Total_Expences: TOTALS.expenses ?? 0,
        Total_Net_Income: TOTALS.net_income ?? 0,
        Total_Income_Cash: TOTALS.cash ?? 0,
        Total_Income_CC: TOTALS.cc ?? 0,
        Patient_Avg_Charge: 0,
        NC_Avg_Charge: AVG_NC ?? 0,
        NP_Avg_Charge: AVG_NP ?? 0,
        NC_and_NP_AVg_Charge: AVG_NC_NP ?? 0,
        Chargeable_VPD: 0,
        Less_Chargeable_VPD: 0,
        Total_VPD: TOTAL_VPD ?? 0,
      }
      const newAverages = []
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          newAverages.push({ Averages: key, Total: obj[key] }) // Aquí puedes aplicar cualquier transformación que desees
        }
      }
      return newAverages
    },
    getExcel() {
      try {
        this.$refs.tablarda.excel()
        new Promise((resolve, reject) => {
          const { newOfficeVisits, specificVisits } = this.processOfficeVisits(
            Object.entries(this.datarda.DETAIL_OFFICE_VISIT)
          )

          const newAverages = this.proccessAverages(this.datarda)
          resolve({
            averages: newAverages,
            visit: newOfficeVisits,
            specific_visits: specificVisits,
            type_visit: this.datarda.COUNT_PER_TYPE,
          })
        }).then(({ averages, visit, type_visit, specific_visits }) => {
          // Crear una nueva hoja de cálculo
          const wb = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(wb, this.dataTable, 'Consults')
          XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(visit), 'Visits')
          XLSX.utils.book_append_sheet(
            wb,
            XLSX.utils.json_to_sheet(specific_visits),
            'Specific Visits'
          )
          XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(averages), 'Averages')
          XLSX.utils.book_append_sheet(
            wb,
            XLSX.utils.json_to_sheet(type_visit),
            'Type Visits'
          )

          // Guardar el archivo
          XLSX.writeFile(wb, 'Report.xlsx')
        })
      } catch (error) {
        this.$refs.toast.danger('An unexpected error has been ocurred!')
      }
    },
  },
}
</script>

<style>
.tarjeta {
  margin-bottom: 1%;
}

.izq {
  display: grid;
  place-content: center;
}

@media (min-width: 970px) {
  .separacion {
    display: none;
  }
}
</style>
