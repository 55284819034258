<template>
    <div>
      <b-row class="d-flex justify-content-between">
        <b-col cols="6" class="mb-4">
          <b-card title="NC">
            <b-table stacked :items="items.NC"></b-table>
          </b-card>
        </b-col>
        <b-col cols="6" class="mb-4">
          <b-card title="NP">
            <b-table stacked :items="items.NP"></b-table>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </template>

<script>
import {
    BCard,
} from "bootstrap-vue";

export default {
    components: {
        BCard,
    },
    props: {
        datarda: {
            type: Object,
            default: () => { },
            required: false
        }
    },
    data() {
        return {
            items: [],
        };
    },
    watch: {
        datarda() {
            if (this.datarda) {
                // console.log(this.datarda.REFERENCES.NC.length)
                this.items = {
                    NC: [],
                    NP: [],
                };
                if (!Array.isArray(this.datarda.REFERENCES.NC)) return
                if (!Array.isArray(this.datarda.REFERENCES.NC)) return
                this.items['NC'].push({
                    [`${this.datarda.REFERENCES.NC[0].references.split(':')[0]}`]: this.datarda.REFERENCES.NC[0].references.split(':')[1] ?? 0,
                    [`${this.datarda.REFERENCES.NC[1].references.split(':')[0]}`]: this.datarda.REFERENCES.NC[1].references.split(':')[1] ?? 0,
                    [`${this.datarda.REFERENCES.NC[2].references.split(':')[0]}`]: this.datarda.REFERENCES.NC[2].references.split(':')[1] ?? 0,
                    [`${this.datarda.REFERENCES.NC[3].references.split(':')[0]}`]: this.datarda.REFERENCES.NC[3].references.split(':')[1] ?? 0,
                    [`${this.datarda.REFERENCES.NC[4].references.split(':')[0]}`]: this.datarda.REFERENCES.NC[4].references.split(':')[1] ?? 0,
                    [`${this.datarda.REFERENCES.NC[5].references.split(':')[0]}`]: this.datarda.REFERENCES.NC[5].references.split(':')[1] ?? 0,
                    [`${this.datarda.REFERENCES.NC[6].references.split(':')[0]}`]: this.datarda.REFERENCES.NC[6].references.split(':')[1] ?? 0,
                    [`${this.datarda.REFERENCES.NC[7].references.split(':')[0]}`]: this.datarda.REFERENCES.NC[7].references.split(':')[1] ?? 0,
                })
                this.items['NP'].push({
                    [`${this.datarda.REFERENCES.NP[0].references.split(':')[0]}`]: this.datarda.REFERENCES.NP[0].references.split(':')[1] ?? 0,
                    [`${this.datarda.REFERENCES.NP[1].references.split(':')[0]}`]: this.datarda.REFERENCES.NP[1].references.split(':')[1] ?? 0,
                    [`${this.datarda.REFERENCES.NP[2].references.split(':')[0]}`]: this.datarda.REFERENCES.NP[2].references.split(':')[1] ?? 0,
                    [`${this.datarda.REFERENCES.NP[3].references.split(':')[0]}`]: this.datarda.REFERENCES.NP[3].references.split(':')[1] ?? 0,
                    [`${this.datarda.REFERENCES.NP[4].references.split(':')[0]}`]: this.datarda.REFERENCES.NP[4].references.split(':')[1] ?? 0,
                    [`${this.datarda.REFERENCES.NP[5].references.split(':')[0]}`]: this.datarda.REFERENCES.NP[5].references.split(':')[1] ?? 0,
                    [`${this.datarda.REFERENCES.NP[6].references.split(':')[0]}`]: this.datarda.REFERENCES.NP[6].references.split(':')[1] ?? 0,
                    [`${this.datarda.REFERENCES.NP[7].references.split(':')[0]}`]: this.datarda.REFERENCES.NP[7].references.split(':')[1] ?? 0,
                })
            }
        }
    },
    methods: {},
};
</script>

<style scoped lang="scss"></style>
