<template>
    <b-card>
        <h1>All procedures in {{ dias }} days
        </h1>
        <vue-apex-charts :options="chartOptions" :series="series" type="bar" />
        <mixn ref="mixin" />
    </b-card>
</template>

<script>
import mixn from "@/components/mixn.vue";
import {
    BContainer,
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";

export default {
    components: {
        BContainer,
        BRow,
        BCol,
        BCard,
        BCardHeader,
        BCardBody,
        BFormInput,
        BButton,
        VueApexCharts,
        mixn
    },
    props: {
        datarda: {
            type: Object,
            default: () => { },
            required: false
        }
    },
    data() {
        return {
            dias: 0,
            chartOptions: {
                dataLabels: {
                    enabled: false,
                },
            },
            series: [],
        };
    },
    watch: {
        datarda() {
            if (this.datarda) {
                const start = this.datarda.dates.start_date
                const end = this.datarda.dates.end_date
                this.dias = this.$refs.mixin.dayDiferences(start, end)
                const arreglo = Object.entries(this.datarda.DETAIL_OFFICE_VISIT)
                const arregloTemp = []
                arreglo.map((visit, indx) => {
                    arregloTemp.push({ name: visit[0], data: [{ x: 'Visits', y: visit[1] }], color: this.$refs.mixin.listaColores(indx) })
                })
                this.series = arregloTemp
                console.log(this.series);
            }
        }
    },
};
</script>

<style scoped lang="scss"></style>
